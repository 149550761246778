import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CompanyDataService from '../services/CompanyDataService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Autocomplete, Checkbox, TextField, Select, MenuItem, Button, Box,
    FormControl, InputLabel, InputAdornment, FormHelperText, Accordion,
    AccordionSummary, AccordionDetails, TextareaAutosize, Typography,
    Grid, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { AUTH_TOKEN } from '../helper';
import { listOfTitles } from '../helper';


const BulkSubsidiaryEdit = ({ selectedSubsidiaries, parentId, onClose }) => {
    let parentType = 'published';
    if (parentId > 10000000) {
        parentType = 'draft';
    }
    console.debug("Parent id: " + parentId);
    const [company, setCompany] = useState({});
    const [formData, setFormData] = useState({}); // Form data state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedParent, setSelectedParent] = useState(null);
    const [parentOptions, setParentOptions] = useState([]);
    const [contacts, setContacts] = useState([
        { id: 'contact1', name: formData.Contact1 || '', title: formData.Contact1Title || '', modified: false },
        { id: 'contact2', name: formData.Contact2 || '', title: formData.Contact2Title || '', modified: false }
    ]);
    const [countryPrefixes, setCountryPrefixes] = useState([]);
    const subsidiaryTypes = [
        'Affiliate Member', 'Branch Office', 'Consumer Relations', 'Country Headquarters',
        'Distribution Center', 'Head Office', 'Headquarters', 'Joint Venture',
        'Manufacturing Facility', 'Production Facility', 'R & D Center', 'Regional Headquarters',
        'Representative Office', 'Retail Location', 'Sales Office', 'Service Centers'];


    useEffect(() => {
        // Initialize empty form data for add mode
        if (parentType === 'draft') {
            setFormData({
                DraftParentID: parentId,
            });
        } else {
            setFormData({
                ParentNID: parentId,
            });
        }
    }, [parentId, parentType]);    


    useEffect(() => {
        const fetchParentOptions = async () => {
            try {
                const response = await CompanyDataService.getAllAutocomplete();
                setParentOptions(response.data.results);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching parent options: ", error);
                setLoading(false);
            }
        };
        fetchParentOptions();
    }, []); // Empty dependency array to run only once on mount
    
    useEffect(() => {
        if (parentOptions.length) {
            // Determine which ID to use for the parent
            const parentID = formData.DraftParentID || formData.ParentNID;
    
            if (parentID) {
                const parentValue = parseInt(parentID, 10);
                const parent = parentOptions.find(option => option.NID === parentValue);
                setSelectedParent(parent || null);
            } else {
                setSelectedParent(null);
            }
        }
    }, [formData, parentOptions]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        let submissionData = { ...formData };
    
        // Iterate over formData keys and update submissionData for empty fields
        Object.keys(formData).forEach(key => {
            const value = formData[key];
            if (typeof value === 'string' && !value.trim()) {
                // Unset submissionData for this key if it's a string and empty.
                delete submissionData[key];
            }
        });
    
        // Now proceed with the updated submissionData
        const items = Array.from(contacts);
        const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));

        const timestamp = new Date().toISOString().split('T')[0];
        let newHistoryEntry = `${timestamp} ${user.user_display_name} bulk updated.`;


        // Initialize updatedFormData with existing submissionData
        const updatedFormData = {
            ...submissionData,
            History: `${newHistoryEntry}\n${submissionData.History || ''}` // Append the new entry before the existing history
        };
    
        // Set Contact1 and Contact1Title if not empty and trimmed
        if (items[0] && items[0].name.trim() !== '') {
            updatedFormData.Contact1 = items[0].name;
            updatedFormData.Contact1Title = items[0].title.trim() !== '' ? items[0].title : undefined;
        }
    
        // Set Contact2 and Contact2Title if not empty and trimmed
        if (items[1] && items[1].name.trim() !== '') {
            updatedFormData.Contact2 = items[1].name;
            updatedFormData.Contact2Title = items[1].title.trim() !== '' ? items[1].title : undefined;
        }
        // Check if DraftParentID is set and greater than the threshold
        if (updatedFormData.DraftParentID && updatedFormData.DraftParentID > 10000000) {
            updatedFormData.ParentNID = null; // Clear ParentNID if DraftParentID is valid
        } 
        // Check if ParentNID is set and greater than the threshold
        else if (updatedFormData.ParentNID && updatedFormData.ParentNID > 10000000) {
            updatedFormData.DraftParentID = updatedFormData.ParentNID; // Move ParentNID to DraftParentID
            updatedFormData.ParentNID = null; // Clear ParentNID
        }
        // If neither ID is greater than the threshold, use DraftParentID as ParentNID (if set) and clear DraftParentID
        else {
            updatedFormData.ParentNID = updatedFormData.DraftParentID || updatedFormData.ParentNID;
            updatedFormData.DraftParentID = null;
        }
        if (selectedSubsidiaries !== 'all') {
            // add an element to updatedFormData called 'subsidiaryList' and set to the NID of each of the selectedSubsidiaries
            updatedFormData.subsidiaryList = [];
            for (const subsidiary of selectedSubsidiaries) {
                updatedFormData.subsidiaryList.push(subsidiary.NID);
            }
        }
        setFormData(updatedFormData);
        try {
            let researcherActivityData = {};
            researcherActivityData.Researcher = user.user_id? user.user_id : null;
            researcherActivityData.Type = 'Sub';
            researcherActivityData.ParentNID = parentId;
            researcherActivityData.FormData = updatedFormData;
            await CompanyDataService.addResearcherActivity(researcherActivityData);
            await CompanyDataService.bulkEdit(parentId, updatedFormData);
        } catch (err) {
            console.error('Error updating all subsidiaries:', err);
            setError('Failed to all subsidiaries');
            return;
        }
        onClose();
        // Call refreshData here if needed
    };
    
    
    // Update form data state
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
        // Update the state based on the input field's name
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value
        }));
        // If 'suggestForRemoval' was set, set
        // 'Status' to 'Suggestions for Removal'
        const timestamp = new Date().toISOString().split('T')[0];
        if (name === 'suggestForRemoval') {
            let newHistoryEntry = `${timestamp} ${user.user_display_name} bulk suggested for removal`;
            setFormData(prevFormData => ({
                ...prevFormData,
                Status: 'Suggested for Removal',
                History: `${newHistoryEntry}\n${prevFormData.History || ''}`
            }));
        }
        // If 'markedDone' was set, set 'Status' to 'Draft'
        if (name === 'markedDone') {
            let newHistoryEntry = `${timestamp} ${user.user_display_name} bulk marked as done`;
            setFormData(prevFormData => ({
                ...prevFormData,
                Status: 'Draft',
                History: `${newHistoryEntry}\n${prevFormData.History || ''}`
            }));
        }

    };
 
    // Conditional rendering based on loading/error states
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;


    // Render each contact
    const renderContact = (contact, index) => (
        <Draggable key={contact.id} draggableId={contact.id} index={index}>
            {(provided) => (
                <Grid container spacing={2} ref={provided.innerRef} {...provided.draggableProps} alignItems="center">
                    <Grid item {...provided.dragHandleProps}> {/* Apply dragHandleProps to the Grid item wrapping the icon */}
                        <DragIndicatorIcon style={{ cursor: 'grab' }} /> {/* Drag Indicator icon */}
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Contact Name"
                            name={`${contact.id}Name`}
                            value={contact.name}
                            onChange={(e) => handleContactChange(e, index)}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id={`${contact.id}Title-label`}>Type</InputLabel>
                            <Select
                                labelId={`${contact.id}Title-label`}
                                id={`${contact.id}Title`}
                                name={`${contact.id}Title`}
                                value={contact.title}
                                label="Type"
                                onChange={(e) => handleContactChange(e, index)}
                            >
                                {listOfTitles.map(option => (
                                    option === '' ? (
                                    <MenuItem key={option} value={option}>
                                        - None -
                                    </MenuItem>
                                    ) : (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                    )
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )}
        </Draggable>
    );
    
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
    
        const items = Array.from(contacts);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setContacts(items);
    
        // Update formData based on the new order of contacts
        const updatedFormData = {
            ...formData,
            Contact1: items[0].name,
            Contact1Title: items[0].title,
            Contact2: items[1].name,
            Contact2Title: items[1].title,
        };
        setFormData(updatedFormData);
    };
    
    
    const handleContactChange = (e, index) => {
        const { name, value } = e.target;
        const field = name.endsWith('Name') ? 'name' : 'title';
        
        setContacts(currentContacts => 
            currentContacts.map((contact, idx) => {
                if (idx === index) {
                    return {
                        ...contact,
                        [field]: value,
                        modified: value.trim() !== '' && value !== contact[field] // Set modified only if non-empty and different from current value
                    };
                }
                return contact;
            })
        );
    };
    

    // Form rendering
    return (
        <Box component="form" onSubmit={handleSubmit}>
            <h2>Bulk Edit Subsidiaries</h2>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Primary Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Autocomplete
                        options={parentOptions}
                        getOptionLabel={(option) => `${option.NID}: ${option.Company}`}
                        renderInput={(params) => <TextField {...params} label="Subsidiary Of" />}
                        value={selectedParent}
                        onChange={(event, newValue) => {
                            if (newValue == null) {
                                setSelectedParent(-1);
                                setFormData({ ...formData, DraftParentID: null, ParentNID: -1 });
                            } else {
                                setSelectedParent(newValue);
                                // Update formData based on the parent type
                                if (parentType === 'draft') {
                                    setFormData({ ...formData, DraftParentID: newValue ? newValue.NID : '' });
                                } else {
                                    setFormData({ ...formData, ParentNID: newValue ? newValue.NID : '', DraftParentID: '' });
                                }
                            }
                            
                        }}
                        isOptionEqualToValue={(option, value) => option.NID === value.NID}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        id="Company"
                        name="Company"
                        label="Subsidiary Name"
                        value={formData.Company || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        name="Email"
                        value={formData.Email || ''}
                        onChange={handleChange}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Phone"
                                name="Telephone"
                                value={formData.Telephone || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Fax"
                                name="Fax"
                                value={formData.Fax || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Website"
                        name="Website"
                        value={formData.Website || ''}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="subsidiarytype-label">Subsidiary Type</InputLabel>
                        <Select
                            labelId="subsidiarytype-label"
                            id="SubsidiaryType"
                            name="SubsidiaryType"
                            value={formData.SubsidiaryType || ''}
                            label="Subsidiary Type"
                            onChange={handleChange}
                        >
                            {subsidiaryTypes.map(option => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* Add checkboxes for 'Suggest for Removal'*/}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.suggestForRemoval || false}
                                onChange={handleChange}
                                name="suggestForRemoval"
                            />
                        }
                        label="Suggest for Removal"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.markedDone || false}
                                onChange={handleChange}
                                name="markedDone"
                            />
                        }
                        label="Mark as Done"
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Main Company Contacts</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="contacts">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {contacts.map((contact, index) => renderContact(contact, index))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </AccordionDetails>
            </Accordion>
            <Button type="submit" color="primary" disabled={loading}>Save</Button>
        </Box>
    );
};

export default BulkSubsidiaryEdit;